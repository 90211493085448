import Auth from "@aws-amplify/auth"
import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import Loading from "../../common/Loading"
import { checkLocalProfile, verifyUser } from "../../../state/actions/user"
import { trackAmplitudeIdentity } from "../../../helpers/amplitude"
import { trackFBIdentity } from "../../../helpers/fbPixel"
import { trackSegmentEvent } from "../../../helpers/segment"
import Amplify from "@aws-amplify/core"
import {} from "../../../state/actions/user"
import API from "@aws-amplify/api"
import awsconfig from "../../../aws-exports"
Amplify.configure(awsconfig)

const ProfileRouteAnonymous = ({
  children,
  localProfileData,
  localProfileFetchStatus,
  localProfileIsComplete,
  checkLocalProfile,
  verifyUser,
}) => {
  useEffect(() => {
    if (localProfileIsComplete) return

    // verify current auth state
    Auth.currentCredentials({ bypassCache: true })
      .then(res => {
        if (res.authenticated === false) {
          // not authenticated, ok to view unauth routes

          checkLocalProfile(API, localProfileData)
        } else {
          // authenticated, need to redirect to myaccount
          return verifyUser(Auth)
            .then(user => {
              if (!user) throw new Error("missing user")
              return user
            })
            .then(user => {
              const { email, sub } = user.attributes
              // identify user in segment every time auth check is called
              trackSegmentEvent("identify", sub, { email })
              trackAmplitudeIdentity(sub, email)
              trackFBIdentity(email)
              navigate("/myaccount/proposals/", { replace: true })
            })
            .catch(e => {
              console.log(e)
              navigate("/signin/", { replace: true })
            })
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err)
      })
  }, []) // eslint-disable-line

  if (localProfileFetchStatus === "ok") {
    if (localProfileIsComplete !== true) {
      // sometimes after the questionnaire results page
      // this page is fired quickly without params at all
      // but then refires quickly thereafter with proper parameters
      // make sure to wait a bit before redirecting people in that case
      // example: user clicks on "confirm these values in questionnaire"
      // -------- and the proposal page (anonymous) loads without params
      // -------- causing a redirection
      if (typeof window !== "undefined") {
        window.redirectTimeout = setTimeout(() => {
          navigate("/questionnaire/", { replace: true })
        }, 2000)
        return <Loading />
      }
    } else {
      if (typeof window !== "undefined") clearTimeout(window.redirectTimeout)
      return children
    }
  } else {
    if (typeof window !== "undefined") clearTimeout(window.redirectTimeout)
    return <Loading />
  }
}

export default connect(
  ({ questionnaire, user }) => {
    return {
      localProfileFetchStatus: user.localProfile.verifyFetchStatus,
      localProfileIsComplete: user.localProfile.isComplete,
      localProfileData: {
        activityId: questionnaire.activityId,
        answers: questionnaire.answers,
        persona: questionnaire.persona,
        startupName: questionnaire.startupName,
      },
    }
  },
  {
    checkLocalProfile,
    verifyUser,
  }
)(ProfileRouteAnonymous)
