/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "OrderSuccess",
            "endpoint": "https://ir5mfxwkm2.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "brandpreview",
            "endpoint": "https://din2h8ly2d.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "cartpreview",
            "endpoint": "https://rmndc8amhg.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "logoconcept",
            "endpoint": "https://b0u6f8m7r2.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "logoproposal",
            "endpoint": "https://oubu13etq4.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "optinbrandingcourse",
            "endpoint": "https://sc3s1lswl5.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "ordercreatesession",
            "endpoint": "https://90u4a1momi.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "personalityscore",
            "endpoint": "https://yhq4504cal.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "registerforbeta",
            "endpoint": "https://e0qcwn5yf2.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "requestcustom",
            "endpoint": "https://rzb6e0i24i.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "submitidea",
            "endpoint": "https://eew6ro65rh.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "userprofileinit",
            "endpoint": "https://bfxvqi3vt6.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "userprofilelocalcheck",
            "endpoint": "https://c1huextb7k.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://47jau44nazehta6h6swylhqlnu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:1b729fc7-77f5-48a3-9706-e5413e93fad6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aeOLBFEKO",
    "aws_user_pools_web_client_id": "18vsr9qt4trn8qlp7cq4g4hhij",
    "oauth": {
        "domain": "logology-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.logology.co/signin-redirect/",
        "redirectSignOut": "https://www.logology.co/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "logology-front-main-storage-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "PreviewLimitIP-production",
            "region": "us-east-1"
        },
        {
            "tableName": "PreviewLimitStartupName-production",
            "region": "us-east-1"
        },
        {
            "tableName": "PreviewLimitUserId-production",
            "region": "us-east-1"
        },
        {
            "tableName": "logoPreviewLimits-production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
