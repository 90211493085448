import React from "react"
import OrderSuccess from "../../../components/Order/OrderSuccess"
import ProfileRouteAnonymous from "../../../components/auth/ProfileRoute/Anonymous"

const SuccessAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <OrderSuccess />
  </ProfileRouteAnonymous>
)

export default SuccessAnonymousPage
