const trackFB = (event, data = {}) => {
  // only track events on live website
  // if (window.location.hostname.indexOf("logology.co") > -1) {
  //   if (window && window.fbq) {
  //     window.fbq("track", event, data)
  //   }
  // } else {
  //   console.log(event, data)
  // }
}

const trackCustomFB = (event, data = {}) => {
  // only track events on live website
  // if (window.location.hostname.indexOf("logology.co") > -1) {
  //   if (window && window.fbq) {
  //     window.fbq("trackCustom", event, data)
  //   }
  // } else {
  //   console.log(event, data)
  // }
}

const trackFBIdentity = email => {
  // if (window.location.hostname.indexOf("logology.co") > -1) {
  //   if (window && window.fbq) {
  //     window.fbq("init", process.env.GATSBY_FACEBOOK_PIXEL, { em: email })
  //   }
  // }
}

export { trackCustomFB, trackFB, trackFBIdentity }
