import React, { useEffect } from "react"
import { connect } from "react-redux"
import {
  Grid3TopBar,
  GridBox,
  GridContainer,
  Title,
  Text,
} from "@thedgbrt/logology-ui-components"
import { Link } from "gatsby"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude, trackAmplitudeRevenue } from "../../helpers/amplitude"
import { getUriParameterByName } from "../../helpers"
import { trackFB } from "../../helpers/fbPixel"

const OrderSuccess = ({ isLoggedIn, url }) => {
  const linkBasePath = isLoggedIn
    ? "/myaccount/proposals"
    : "/anonaccount/proposals"

  const currency = getUriParameterByName("currency", url)
  const value = getUriParameterByName("value", url)

  useEffect(() => {
    trackSegmentEvent("track", "Payment success", {
      currency,
      revenue: value,
    })
    trackAmplitude("Payment success")
    trackAmplitudeRevenue(value, 1)
    trackFB("Purchase", { currency, value })
  }, []) // eslint-disable-line

  return (
    <>
      <Grid3TopBar>
        <Link to={`${linkBasePath}/`}>← back to proposals</Link>
      </Grid3TopBar>
      <GridContainer type={3}>
        <GridBox colSpans={[[2, 5], [2, 5], [2, 6]]} type={3}>
          <Title level={1} standalone>
            Your order is complete!
          </Title>
        </GridBox>
      </GridContainer>
      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 5]]} type={4} withBorder>
          <Text italic lead standalone>
            <p>
              <b>Thank you for your purchase!</b>
            </p>
            <p>
              Your logo package will be sent shortly to your email address. If
              you don't see it, make sure to check your spam folder. Contact us
              at <a href="mailto:support@logology.co">support@logology.co</a>{" "}
              for any question.
            </p>
          </Text>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default connect(
  ({ user }) => ({ isLoggedIn: user.isLoggedIn }),
  {}
)(OrderSuccess)
